<template>
  <div id="canvas-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link class="btn btn-primary mb-5" to="/canvas/add">Werteversprechen hinzufügen</router-link>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Calculations</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/experiments">Experiments</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
    </ul>-->

    <IntroBanner v-if="!(canvas && canvas.length)" link="/canvas/add"></IntroBanner>

    <div v-for="(canva, index) in canvas" class="card mb-5" :key="'c' + index">
      <router-link :to="'/canvas/edit/' + canva.id" class="edit-button">
        <i class="fad fa-pencil"></i>
      </router-link>
      <div class="remove-button" @click="remove(canva.id)">
        <i class="fas fa-times"></i>
      </div>
      <div class="card-header">
        <h2 class="mb-0">{{ canva.title || '&lt;Unbenannt&gt;' }}</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h3>Unternehmensseite</h3>
          </div>
          <div class="col-sm-6">
            <h3>Kundenseite</h3>
          </div>
          <div class="col-sm-6 mt-2">
            <div class="card h-100">
              <div class="card-body px-3 pt-3 pb-0">
                <h4><i class="fad fa-fw fa-plus-circle text-success"></i> Welche Vorteile du lieferst</h4>
                <ul class="list-unstyled">
                  <li v-for="(gainCreator, index) in canva.gainCreators" :key="index">{{ gainCreator }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mt-2">
            <div class="card h-100">
              <div class="card-body px-3 pt-3 pb-0">
                <h4><i class="fad fa-fw fa-wrench text-success"></i> Was sie suchen</h4>
                <ul class="list-unstyled">
                  <li v-for="(gain, index) in canva.gains" :key="index">{{ gain }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mt-2">
            <div class="card h-100">
              <div class="card-body px-3 pt-3 pb-0">
                <h4><i class="fad fa-fw fa-box-open text-primary"></i> Produkte & Services</h4>
                <ul class="list-unstyled">
                  <li v-for="(product, index) in canva.products" :key="index">{{ product }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mt-2">
            <div class="card h-100">
              <div class="card-body px-3 pt-3 pb-0">
                <h4>
                  <i class="fad fa-construction"></i> Zu erledigende Jobs
                </h4>
                <ul class="list-unstyled">
                  <li v-for="(job, index) in canva.jobs" :key="index">
                    <i class="fad fa-fw" :class="job.type === 'functional' ? 'fa-wrench' : job.type === 'social' ? 'fa-users' : 'fa-heart'"></i>
                    {{ job.title }}
                    <router-link :to="'/jobs/' + job.id" alt="Zum job springen" title="Zum Job springen">
                      <i class="fad fa-arrow-right"></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mt-2">
            <div class="card h-100">
              <div class="card-body px-3 pt-3 pb-0">
                <h4><i class="fad fa-fw fa-band-aid text-warning"></i> Welche Probleme du behebst</h4>
                <ul class="list-unstyled">
                  <li v-for="(painReliever, index) in canva.painRelievers" :key="index">{{ painReliever }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mt-2">
            <div class="card h-100">
              <div class="card-body px-3 pt-3 pb-0">
                <h4><i class="fad fa-fw fa-bolt text-warning"></i> Was sie vermeiden wollen</h4>
                <ul class="list-unstyled">
                  <li v-for="(pain, index) in canva.pains" :key="index">{{ pain }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h3 class="mt-3">Einzigartige Wertversprechen</h3>
        <div class="card">
          <div class="card-body">
            <ul v-if="canva.valueProps.length" class="fa-ul">
              <li v-for="(valueProp, index) in canva.valueProps" class="lead my-3" :key="index">
                <span class="fa-li text-primary"><i class="fad fa-gem"></i></span>{{ valueProp }}
              </li>
            </ul>
            <div v-else class="text-muted">
              <p>Keine UVPs vorhanden</p>
              <router-link to="/canvas/edit" class="btn btn-primary">UVPs hinzufügen</router-link>
            </div>
          </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

import IntroBanner from "@/components/IntroBanner";
export default {
  name: 'Canvas',
  components: {IntroBanner},
  data() {
    return {
    }
  },
  computed: {
    jobs() {
      return this.project.jobs;
    },
    canvas() {
      return this.project.canvas.map(canva => {
        canva.jobs = canva.jobIds.map(jobId => this.project.jobs.find(job => job.id === jobId));
        return canva;
      });
    }
  },
  methods: {
    remove(id) {
      if (confirm("Bist du sicher, dass du den Canvas löschen willst?")) {
        let index = this.project.canvas.findIndex(c => c.id === id);
        this.project.canvas.splice(index, 1);
        this.$store.dispatch('project/updateProjectByProp', {prop: 'canvas', data: this.project.canvas})
            .then(res => {
              console.debug(res);
            });
      }
    }
  },
  mounted() {
    this.$forceUpdate();
  }
}
</script>
